import React from 'react';
import { Card, Col } from 'react-bootstrap';

const CommonLoading = ({ hasPadding }) => {
    return (
        <div>
            {/* <Col sm={12} lg={12} className='d-flex justify-content-center'> */}
            <Col sm={12} lg={12} className={`d-flex justify-content-center ${hasPadding === false ? "" : "py-5"}`}>
                <div id="loaders5">
                    <div className="text-center">
                        <div className="lds-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>

            </Col>
        </div>
    );
};

export default CommonLoading;